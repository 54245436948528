import React, {useEffect, useState} from 'react';
import Nav from './components/Nav'
import Table from './components/Table'
import Title from './components/Title'
import Index from './components/Index'
import { Router, Redirect, useLocation } from "@reach/router"

const GROUPS = [
  { id: 1 , long:"Grupo 01", short:"G-01"  ,title:"Quimicos y Mantenimiento"},
  { id: 2 , long:"Grupo 02", short:"G-02", title:"Acc de Limipieza"},
  { id: 3 , long:"Grupo 03", short:"G-03", title:"Filtros y Bombas"},
  { id: 4 , long:"Grupo 04", short:"G-04", title:"Calentadores"},
  { id: 5 , long:"Grupo 05", short:"G-05", title:"Automatizacion"},
  { id: 6 , long:"Grupo 06", short:"G-06", title:"Iluminacion"},
  { id: 7 , long:"Grupo 07", short:"G-07", title:"Amenidades"},
  { id: 8 , long:"Grupo 08", short:"G-08", title:"Acc de Empotrar"},
  { id: 9 , long:"Grupo 09", short:"G-09", title:"Recubrimiento"},
  { id: 10, long:"Grupo 10", short:"G-10", title:"Tinas"},
  { id: 12, long:"Grupo 12", short:"G-12", title:"Tuberia"},
  { id: 15, long:"Grupo 15", short:"G-15", title:"Construccion"},
];

const TODOSLINKS = [
  { id: 1  ,link:"shrtFmfPQbGskJN7h"},
  { id: 2  ,link:"shrVw2MfZAOvjULgI"},
  { id: 3  ,link:"shrSYKBN9q0zenSpi"},
  { id: 4  ,link:"shrezJmBUCjKGehdk"},
  { id: 5  ,link:"shrBU5tnJw9W4wF7R"},
  { id: 6  ,link:"shremWo0DOPI9vFTS"},
  { id: 7  ,link:"shrotjf9s8hwJn0Fa"},
  { id: 8  ,link:"shrroD6GN7LK8rC9o"},
  { id: 9  ,link:"shr8uYVaPKKoUESRU"},
  { id: 10 ,link:"shrsmpO5pS8yl7DNE"},
  { id: 12 ,link:"shrVQ4eBS90F8E0Ua"},
  { id: 15 ,link:"shrmuLOQLCoqq0e9b"},
];

const VENTASLINKS = [
  { id: 1  ,link:"shrY97aQHMBWdvCbb"},
  { id: 2  ,link:"shrxNiSuId0msKcho"},
  { id: 3  ,link:"shrLLQSIG8NOxUFg5"},
  { id: 4  ,link:"shrI3Zl40Mzs1WUzP"},
  { id: 5  ,link:"shrJ4yZuy56AlXkIm"},
  { id: 6  ,link:"shrCZ1VedcbBncm62"},
  { id: 7  ,link:"shrl0ipEsQaqccdos"},
  { id: 8  ,link:"shr68q6T3RP2fdMUF"},
  { id: 9  ,link:"shr26FMp9es2aRz9g"},
  { id: 10 ,link:"shra9zYcNwx8rGaPo"},
  { id: 12 ,link:"shrefQ6pLyTOGOQeg"},
  { id: 15 ,link:"shrvFc7WfhSGC5GYe"},
];

const CLIENTESLINKS = [
  { id: 1  ,link:"shrNY8jvNWLiERc3r"},
  { id: 2  ,link:"shrSCUS9FEHnuFni1"},
  { id: 3  ,link:"shruX48HsqzspLxIJ"},
  { id: 4  ,link:"shrcuIEGkUlQqVjTG"},
  { id: 5  ,link:"shrJapneeRKgQZ1PT"},
  { id: 6  ,link:"shrOYbvqLF5HGabxA"},
  { id: 7  ,link:"shrZCM5dQoWjPxR41"},
  { id: 8  ,link:"shrygIFegl2s1wNYN"},
  { id: 9  ,link:"shrWvPYZg3GGA0EGQ"},
  { id: 10 ,link:"shrIjKuiOTaRiDl2f"},
  { id: 12 ,link:"shrgwZwjLzui7eYar"},
  { id: 15 ,link:"shrBWLJnMIP8S8QWM"},
];


const App = () => {

  return (
    <div className="App">
      <Router>
        <Nav path="/todos" groups={GROUPS} id="0">
          Not Found
        </Nav>
        <Nav path="/ventas" groups={GROUPS} id="0">
          Not Found
        </Nav>
        <Nav path="/clientes" groups={GROUPS} id="0">
          Not Found
        </Nav>
        <Nav path="/todos/:id" groups={GROUPS}>
          Not Found
        </Nav>
        <Nav path="/ventas/:id" groups={GROUPS}>
          Not Found
        </Nav>
        <Nav path="/clientes/:id" groups={GROUPS}>
          Not Found
        </Nav>
      </Router>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
          <Router>
            <Title path="/todos" id="0" groups={GROUPS}>
              Not Found
            </Title>
            <Title path="/ventas" id="0" groups={GROUPS}>
              Not Found
            </Title>
            <Title path="/clientes" id="0" groups={GROUPS}>
              Not Found
            </Title>
            <Title path="/todos/:id" groups={GROUPS}>
              Not Found
            </Title>
            <Title path="/ventas/:id" groups={GROUPS}>
              Not Found
            </Title>
            <Title path="/clientes/:id" groups={GROUPS}>
              Not Found
            </Title>
          </Router>
           
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <Router>
              <Index path="/todos" groups={GROUPS}>
                Not Found
              </Index>
              <Index path="/ventas" groups={GROUPS}>
                Not Found
              </Index>
              <Index path="/clientes" groups={GROUPS}>
                Not Found
              </Index>
              <Table path="/todos/:id" groups={GROUPS} links={TODOSLINKS}>
                Not Found
              </Table>
              <Table path="/ventas/:id" groups={GROUPS} links={VENTASLINKS}>
                Not Found
              </Table>
              <Table path="/clientes/:id" groups={GROUPS} links={CLIENTESLINKS}>
                Not Found
              </Table>
            </Router>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
