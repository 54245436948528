import React from 'react';
import IFrameAirtable from './IFrameAirtable'

const getGroup = (id, groups) => {
  return groups.find(
    item => item.id === parseInt(id)
  );
};

const getLinkHash = (id, groups, links) => {
  if( getGroup(id, groups)) {
    return getGroup(id, links).link;
  }
  // Do a rederict right here.
  throw new Error('The id parameter in getLinkHash is not supported.');
};

const Table = ({ groups, links, id }) => {
  return (
    <IFrameAirtable linkHash={getLinkHash(id, groups, links)}/>
  )
}


export default Table;
