import React from 'react';


const LinksList = ({ groups, path, id, extraClass }) => {
  let className = "px-3 py-2 rounded-md text-sm font-medium "+ extraClass;
  let selected = " bg-gray-900 text-white";
  let notSelected = " text-gray-300 hover:bg-gray-700 hover:text-white";

  return (
    groups.map(group => {
      let currentClass = (group.id === parseInt(id)) ? (className + selected) : (className + notSelected);
      return (
        <a href={ path + group.id} class={currentClass}>
          {group.short}
        </a>
      );
    })
  );

};

export default LinksList;

