import React from 'react';

const IFrameAirtable = ({ linkHash }) => {
    return (
        <iframe className="airtable-embed" 
          src={"https://airtable.com/embed/"+ linkHash +"?backgroundColor=blueLight&viewControls=on"} 
          title={linkHash}
          frameborder="0" 
          onmousewheel="" 
          width="100%" 
          height="800"></iframe>
    );
}

export default IFrameAirtable;
