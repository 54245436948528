import React, { useState } from 'react';
import LinksList from './LinksList'

const removePathIdTag = (path) => {
  if(path.endsWith(":id")) {
    return path.substring(0, path.length-3);
  }
  return path+"/";
};

const Nav = ({ groups, path, id }) => {
  const [menuClicked, setMenuClicked] = useState(false);
  
    return (
      <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <a href={removePathIdTag(path)}>
                <img class="h-8" src="https://storage.googleapis.com/lemscript-com/hidroequipos/inventario/logoHidro.png" alt="Hidroequipos" />
                </a>
              </div>
              <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-4">

                  <LinksList groups={groups} path={removePathIdTag(path)} id={id} extraClass="text-sm" />

                </div>
              </div>
            </div>
            <div class="-mr-2 flex md:hidden" onClick={() => setMenuClicked(!menuClicked)} >
              <button class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open main menu</span>

                <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>

                <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class={ (menuClicked ? "": "hidden") + " md:hidden"} >
          <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">

            <LinksList groups={groups} path={removePathIdTag(path)} id={id} extraClass="block text-base" />

          </div>
        </div>
      </nav>
    );

};

export default Nav;
