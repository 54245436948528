import React from 'react';
import { Match } from "@reach/router"

const getTitle = (id, groups) => {
  if(parseInt(id) === 0){
      return "Indice";
  }
  let group = groups.find(
    item => item.id === parseInt(id)
  );
  if(group){
    return group.title;
  }
  return "No Encontrado";
};

const Title = ({ groups, id }) => {
    console.log(id);
    return (
      <div>{getTitle(id, groups)}</div>
    )
}

export default Title;
